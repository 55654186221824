import { Box, chakra } from "@chakra-ui/react";
import React, { MouseEvent } from "react";

import Drawer from "../../../shared/components/drawer";
import Link from "../../../shared/components/link";
import { trackEvent } from "../../../shared/lib/analytics";

import { NavbarItem } from "./linkItems";

import { NavbarMenuToggleProps } from "./index";

const zIndex = 1450;

const NavItem = ({
  event,
  href,
  isOpen,
  label,
  labelPrefix,
  onToggle,
  subItems: SubItems,
}: NavbarItem & Partial<NavbarMenuToggleProps>) => {
  const btnRef = React.useRef<HTMLButtonElement>(null);
  const handleClick = (e: MouseEvent) => {
    e.preventDefault();
    if (event && !isOpen) {
      trackEvent(event);
    }
    if (onToggle) {
      onToggle();
    }
  };

  return (
    <>
      <Link
        as="button"
        display={{ base: "none", lg: "block" }}
        href={href}
        ref={btnRef}
        variant="navbar"
        onClick={SubItems ? handleClick : undefined}
      >
        {({ isActive }) => (
          <chakra.span color={isOpen ?? isActive ? "tertiary" : undefined}>
            <Box textStyle="bodySmall">{labelPrefix}</Box>
            <Box textStyle="menuItem">{label}</Box>
          </chakra.span>
        )}
      </Link>
      {SubItems && isOpen && onToggle && (
        <Drawer
          btnRef={btnRef}
          isOpen={isOpen}
          placement="top"
          zIndex={zIndex}
          onClose={onToggle}
        >
          <SubItems handleClose={onToggle} />
        </Drawer>
      )}
    </>
  );
};

export default NavItem;
