import { Container } from "@chakra-ui/react";
import React, { FunctionComponent, MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

import useCurrentUser from "../../../shared/hooks/useCurrentUser";
import PillarPageAccordion, {
  PillarPageAccordionVariant,
} from "../pillarPages/PillarPageAccordion";

const PILLAR_GRID_COLUMNS = {
  base: "repeat(1, 1fr)",
  md: "repeat(3, 1fr)",
  lg: "repeat(4, 1fr)",
  xl: "repeat(5, 1fr)",
};

export interface NavbarItem {
  event?: string;
  href: string;
  label: string;
  labelPrefix?: string;
  subItems?: FunctionComponent<{
    handleClose: MouseEventHandler;
    mobile?: boolean;
  }>;
}

const useMenuItems = (): [NavbarItem, NavbarItem[]] => {
  const [currentUser] = useCurrentUser();
  const { t } = useTranslation();

  const pillarMenuItem: NavbarItem = {
    event: "click_menuonderwerpen",
    href: "/onderwerpen",
    label: t("main:navbar.menuItems.pillarPages.label")!,
    labelPrefix: t("main:navbar.menuItems.pillarPages.labelPrefix")!,
    subItems: ({ mobile, handleClose }) => (
      <Container maxWidth="1370px" paddingX={0}>
        <PillarPageAccordion
          gridColumns={PILLAR_GRID_COLUMNS}
          variant={
            mobile
              ? PillarPageAccordionVariant.MobileNavbar
              : PillarPageAccordionVariant.Navbar
          }
          onClick={handleClose}
        />
      </Container>
    ),
  };

  const menuItems: NavbarItem[] = [];

  if (currentUser?.attributes.isGuide) {
    menuItems.push({
      href: "/dashboard",
      label: t("main:navbar.menuItems.conversations.label")!,
      labelPrefix: t("main:navbar.menuItems.conversations.labelPrefix")!,
    });
  } else {
    menuItems.push({
      href: "/vrijwilligers",
      label: t("main:navbar.menuItems.volunteers.label")!,
      labelPrefix: t("main:navbar.menuItems.volunteers.labelPrefix")!,
    });
    menuItems.push({
      href: "/over-ons",
      label: t("main:navbar.menuItems.about.label")!,
      labelPrefix: t("main:navbar.menuItems.about.labelPrefix")!,
    });
  }

  return [pillarMenuItem, menuItems];
};

export default useMenuItems;
