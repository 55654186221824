import { Box, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import {
  AsyncSelect,
  MenuProps,
  SelectInstance,
  SingleValue,
  chakraComponents,
} from "chakra-react-select";
import { ChakraStylesConfig } from "chakra-react-select/dist/types/types";
import { t } from "i18next";
import React, { useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { useInRouterContext, useNavigate } from "react-router-dom";
import { GroupBase } from "react-select/dist/declarations/src/types";

import useResourceRoute from "../../../shared/components/store/hooks/useResourceRoute";
import useInstantSearch, {
  AsyncCallback,
} from "../../../shared/hooks/useInstantSearch";
import useIsMounted from "../../../shared/hooks/useIsMounted";
import useStore from "../../../shared/hooks/useStore";
import { trackEvent } from "../../../shared/lib/analytics";
import { getCollectionId } from "../../../shared/lib/ids";
import redirect from "../../../shared/lib/redirect";
import toString from "../../../shared/lib/toString";
import { EmptyResource, ResourceType } from "../../../types/serializers";

import NavbarMobileButton from "./MobileNavbarButton";

const PAGE_SIZE = 5;
const SEE_ALL = "seeAll";

const legacyNavigate = (location: string) => {
  redirect(GLOBALS.root + location);
};

type OptionType = EmptyResource | { id: undefined; type: typeof SEE_ALL };

const DropdownIndicator = () => (
  <Box paddingX="0.5em" pointerEvents="none">
    <FiSearch color="gray.300" />
  </Box>
);

const FallbackInput = () => (
  <InputGroup>
    <Input placeholder={t("main:navbar.search.label")!} />
    <InputRightElement>
      <DropdownIndicator />
    </InputRightElement>
  </InputGroup>
);

const Menu = (props: MenuProps<OptionType>) => {
  if (!props.selectProps.inputValue) {
    return null;
  }

  return (
    <chakraComponents.Menu {...props}>{props.children}</chakraComponents.Menu>
  );
};

const searchComponents = {
  DropdownIndicator: DropdownIndicator,
  Menu: Menu,
};

const searchStyles: ChakraStylesConfig<
  OptionType,
  false,
  GroupBase<OptionType>
> = {
  container: (provided) => ({
    ...provided,
    cursor: "text",
    width: "100%",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    borderColor: "white",
  }),
};

const NavbarSearch = () => {
  const store = useStore();
  const legacyComponent = !useInRouterContext();
  const ref = useRef<SelectInstance<OptionType> | null>(null);
  const navigate = legacyComponent ? legacyNavigate : useNavigate();
  const [query, setQuery] = useState("");
  const [mobileExpand, setMobileExpand] = useState(false);
  const mounted = useIsMounted();
  const seeAllRoute = useResourceRoute({
    id: getCollectionId(ResourceType.Blog, { query }),
    type: ResourceType.Collection,
  })!;

  const handleSelect = (option: SingleValue<OptionType>) => {
    if (option) {
      if (option.type === SEE_ALL) {
        trackEvent("instant_search_see_all", { query });
        navigate(seeAllRoute);
      } else {
        const path = store.router.resourceRoute(option)!;
        trackEvent("instant_search_click", { path, query });
        navigate(path);
      }
    }
    setMobileExpand(false);
  };

  const loadOptions = useInstantSearch<OptionType>(ResourceType.Blog, {
    pageSize: PAGE_SIZE,
  });

  const loadOptionsWithSeeAll = (
    inputValue: string,
    callback: AsyncCallback<OptionType>
  ) => {
    loadOptions(inputValue, (options) => {
      if (options.length === PAGE_SIZE) {
        callback([...options, { id: undefined, type: SEE_ALL }]);
      } else {
        callback(options);
      }
    });
  };
  const formatOptionLabel = (option: OptionType) => {
    if (option.type === SEE_ALL) {
      return <strong>{t("shared:search.seeAll")}</strong>;
    }

    return toString(store.getResource(option.type, option.id));
  };

  return (
    <>
      <Box display={{ base: "block", md: "none" }}>
        <NavbarMobileButton
          icon={FiSearch}
          title={t("shared:search.label")!}
          onClick={(e) => {
            e.preventDefault();
            setMobileExpand(true);
            ref.current?.focus();
          }}
        />
      </Box>
      <InputGroup
        left={{ base: 0, lg: undefined }}
        paddingX="1em"
        position={{ base: "absolute", md: "relative" }}
        top={{ base: "4.5em", md: "0" }}
        visibility={{
          base: mobileExpand ? "visible" : "hidden",
          md: "visible",
        }}
        width={{
          base: mobileExpand ? "100%" : "0",
          md: "300px",
          lg: "350px",
          xl: "400px",
        }}
      >
        {mounted ? (
          <AsyncSelect<OptionType>
            menuIsOpen
            chakraStyles={searchStyles}
            components={searchComponents}
            defaultOptions={[]}
            formatOptionLabel={formatOptionLabel}
            loadOptions={loadOptionsWithSeeAll}
            loadingMessage={() => t("main:navbar.search.loading")}
            maxMenuHeight={500}
            name="search-input"
            noOptionsMessage={() => t("main:navbar.search.noOptions")}
            placeholder={t("main:navbar.search.label")}
            ref={ref}
            value={null}
            onBlur={() => setMobileExpand(false)}
            onChange={handleSelect}
            onInputChange={setQuery}
          />
        ) : (
          <FallbackInput />
        )}
      </InputGroup>
    </>
  );
};

export default NavbarSearch;
