import { Box, Icon, useDisclosure } from "@chakra-ui/react";
import React, { MouseEvent, MouseEventHandler } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

import Link from "../../../shared/components/link";

import { NavbarItem } from "./linkItems";

const MobileNavbarItem = ({
  handleClose,
  href,
  labelPrefix,
  label,
  subItems: SubItems,
}: NavbarItem & { handleClose: MouseEventHandler }) => {
  const { isOpen, onToggle } = useDisclosure();
  const handleClick = (e: MouseEvent) => {
    e.preventDefault();
    onToggle();
  };

  return (
    <Box borderBottom="1px solid lightgray">
      <Link href={href} onClick={SubItems ? handleClick : handleClose}>
        <Box color="blackish" paddingY="0.5em" textStyle="menuItemMobile">
          {labelPrefix} {label}
          {SubItems && (
            <Icon
              as={isOpen ? FiChevronUp : FiChevronDown}
              float="right"
              fontSize="22px"
              verticalAlign="middle"
            />
          )}
        </Box>
      </Link>
      {SubItems && isOpen && <SubItems mobile handleClose={handleClose} />}
    </Box>
  );
};

export default MobileNavbarItem;
