import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Grid,
  GridItem,
  HStack,
  Heading,
  SkeletonText,
  Text,
  chakra,
} from "@chakra-ui/react";
import { ResponsiveValue } from "@chakra-ui/styled-system";
import * as CSS from "csstype";
import React, { MouseEventHandler, useMemo } from "react";

import Link from "../../../shared/components/link";
import useCollection from "../../../shared/hooks/useCollection";
import useResources from "../../../shared/hooks/useResources";
import useStatus from "../../../shared/hooks/useStatus";
import useStore from "../../../shared/hooks/useStore";
import categories from "../../../shared/lib/categories";
import { getCollectionId } from "../../../shared/lib/ids";
import { isLoadingStatus } from "../../../shared/lib/status";
import { PillarPageResource, ResourceType } from "../../../types/serializers";

export enum PillarPageAccordionVariant {
  Default,
  Navbar,
  MobileNavbar,
  Footer,
}

const ICON_WIDTH: {
  [variant in PillarPageAccordionVariant]: string | undefined;
} = {
  [PillarPageAccordionVariant.Default]: "4em",
  [PillarPageAccordionVariant.Footer]: undefined,
  [PillarPageAccordionVariant.Navbar]: "4em",
  [PillarPageAccordionVariant.MobileNavbar]: "2em",
};

const LABEL_HEADER: {
  [variant in PillarPageAccordionVariant]: string | undefined;
} = {
  [PillarPageAccordionVariant.Default]: "md",
  [PillarPageAccordionVariant.Footer]: "xs",
  [PillarPageAccordionVariant.Navbar]: "md",
  [PillarPageAccordionVariant.MobileNavbar]: undefined,
};

const BACKGROUND_COLORS: { [variant in PillarPageAccordionVariant]: string } = {
  [PillarPageAccordionVariant.Default]: "background.card",
  [PillarPageAccordionVariant.Footer]: "none",
  [PillarPageAccordionVariant.Navbar]: "none",
  [PillarPageAccordionVariant.MobileNavbar]: "background.banner",
};

const GRID_GAP: { [variant in PillarPageAccordionVariant]: string } = {
  [PillarPageAccordionVariant.Default]: "6",
  [PillarPageAccordionVariant.Footer]: "6",
  [PillarPageAccordionVariant.Navbar]: "6",
  [PillarPageAccordionVariant.MobileNavbar]: "1em",
};

const PADDINGS: { [variant in PillarPageAccordionVariant]: string | number } = {
  [PillarPageAccordionVariant.Default]: 5,
  [PillarPageAccordionVariant.Footer]: 0,
  [PillarPageAccordionVariant.Navbar]: 0,
  [PillarPageAccordionVariant.MobileNavbar]: 5,
};

const BUTTON_PADDING_TOP: { [variant in PillarPageAccordionVariant]: string } =
  {
    [PillarPageAccordionVariant.Default]: "1em",
    [PillarPageAccordionVariant.Footer]: "1em",
    [PillarPageAccordionVariant.Navbar]: "0",
    [PillarPageAccordionVariant.MobileNavbar]: "0.5em",
  };

const BUTTON_PADDING_BOTTOM: {
  [variant in PillarPageAccordionVariant]:
    | string
    | ResponsiveValue<string | number>;
} = {
  [PillarPageAccordionVariant.Default]: { base: 5, md: 0 },
  [PillarPageAccordionVariant.Footer]: { base: 5, md: 0 },
  [PillarPageAccordionVariant.Navbar]: { base: 5, md: 3 },
  [PillarPageAccordionVariant.MobileNavbar]: "0.5em",
};

interface Category {
  icon: string;
  label: string;
  pillarPages: PillarPageResource[];
}

const PillarPageList = ({
  category,
  variant,
  onClick,
}: {
  category: Category;
  onClick?: MouseEventHandler;
  variant: PillarPageAccordionVariant;
}) => {
  const store = useStore();

  return (
    <HStack>
      {variant === PillarPageAccordionVariant.Default && (
        <Box width={ICON_WIDTH[variant]} />
      )}
      <Box>
        {category.pillarPages.map((pillarPage) => (
          <Box key={pillarPage.attributes.slug} marginY="1em">
            <Link
              href={store.router.resourceRoute(pillarPage)}
              onClick={onClick}
            >
              {pillarPage.attributes.title}
              {!pillarPage.attributes.published && " (concept)"}
            </Link>
          </Box>
        ))}
      </Box>
    </HStack>
  );
};

const PillarPageItem = ({
  category,
  loading,
  variant,
  onClick,
}: {
  category: Category;
  loading: boolean;
  onClick?: MouseEventHandler;
  variant: PillarPageAccordionVariant;
}) => {
  if (loading) {
    return <SkeletonText />;
  }

  if (category.pillarPages.length === 0) {
    return null;
  }

  const backgroundColor = BACKGROUND_COLORS[variant];
  const padding = PADDINGS[variant];
  const buttonPaddingTop = BUTTON_PADDING_TOP[variant];
  const buttonPaddingBottom = BUTTON_PADDING_BOTTOM[variant];

  return (
    <GridItem
      __css={{
        "& button:hover": {
          background: { base: undefined, md: "none" },
          cursor: { base: undefined, md: "default" },
        },
      }}
      backgroundColor={backgroundColor}
      borderRadius={8}
      display="flex"
    >
      <AccordionItem border="none" width="100%">
        <AccordionButton
          background="transparent"
          border="none"
          paddingBottom={buttonPaddingBottom}
          paddingTop={buttonPaddingTop}
          paddingX={padding}
        >
          <HStack flex="1" textAlign="left">
            {ICON_WIDTH[variant] && (
              <Box width={ICON_WIDTH[variant]}>
                <chakra.img
                  alt={category.label}
                  maxWidth="100%"
                  src={category.icon}
                />
              </Box>
            )}
            {LABEL_HEADER[variant] ? (
              <Heading marginBottom={0} variant={LABEL_HEADER[variant]}>
                {category.label}
              </Heading>
            ) : (
              <Text textStyle="body">{category.label}</Text>
            )}
          </HStack>
          <AccordionIcon display={{ base: "inline-block", md: "none" }} />
        </AccordionButton>
        <AccordionPanel display={{ base: "block", md: "none" }} pb={4}>
          <PillarPageList
            category={category}
            variant={variant}
            onClick={onClick}
          />
        </AccordionPanel>
        <Box
          display={{ base: "none", md: "block" }}
          paddingBottom={padding}
          paddingX={padding}
        >
          <PillarPageList
            category={category}
            variant={variant}
            onClick={onClick}
          />
        </Box>
      </AccordionItem>
    </GridItem>
  );
};

interface PillarPageAccordionProps {
  className?: string;
  gridColumns: ResponsiveValue<CSS.Property.GridTemplateColumns>;
  onClick?: MouseEventHandler;
  variant?: PillarPageAccordionVariant;
}
const PillarPageAccordion = ({
  className,
  gridColumns,
  variant = PillarPageAccordionVariant.Default,
  onClick,
}: PillarPageAccordionProps) => {
  const collection = useCollection(ResourceType.PillarPage);
  const status = useStatus({
    id: getCollectionId(ResourceType.PillarPage),
    type: ResourceType.Collection,
  });
  const pillarPages = useResources(collection?.relationships.items.data);
  const categorisedPillarPages = useMemo(
    () =>
      categories.map((category) => ({
        ...category,
        pillarPages: pillarPages.filter((pillarPage) =>
          pillarPage?.attributes.categoryList?.includes(category.label)
        ) as PillarPageResource[],
      })),
    [pillarPages]
  );
  const mobileNavbar = variant === PillarPageAccordionVariant.MobileNavbar;

  return (
    <Accordion allowMultiple className={className}>
      <Grid
        gap={GRID_GAP[variant]}
        paddingBottom={mobileNavbar ? "1em" : undefined}
        templateColumns={gridColumns}
      >
        {categorisedPillarPages.map((category) => (
          <PillarPageItem
            category={category}
            key={category.label}
            loading={isLoadingStatus(status)}
            variant={variant}
            onClick={onClick}
          />
        ))}
      </Grid>
    </Accordion>
  );
};

export default PillarPageAccordion;
