import React from "react";
import { useTranslation } from "react-i18next";
import { FiUser, FiUserCheck } from "react-icons/fi";

import useCurrentUser from "../../../shared/hooks/useCurrentUser";

import NavbarMobileButton from "./MobileNavbarButton";

const SignInButton = () => {
  const { t } = useTranslation();

  return (
    <NavbarMobileButton
      color="white"
      icon={FiUser}
      label={t("main:navbar.signIn")!}
      to="/users/sign_in"
    />
  );
};

const UserMenuButton = () => {
  const [currentUser] = useCurrentUser();

  return (
    <NavbarMobileButton
      color="white"
      icon={FiUserCheck}
      label={currentUser!.attributes.firstname}
      to="/account"
    />
  );
};

const NavbarLogin = () => {
  const [currentUser] = useCurrentUser();

  return currentUser ? <UserMenuButton /> : <SignInButton />;
};

export default NavbarLogin;
