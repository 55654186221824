import {
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
} from "@chakra-ui/react";
import { SlideDirection } from "@chakra-ui/transition/dist/transition-utils";
import React, { PropsWithChildren, RefObject } from "react";

interface DrawerProps extends PropsWithChildren {
  btnRef: RefObject<HTMLButtonElement>;
  footer?: JSX.Element;
  isOpen?: boolean;
  onClose: () => void;
  placement?: SlideDirection | "start" | "end";
  size?: string;
  zIndex?: number;
}

const Drawer = ({
  children,
  btnRef,
  footer,
  isOpen,
  onClose,
  placement = "right",
  size,
  zIndex,
}: DrawerProps) => {
  return (
    <ChakraDrawer
      finalFocusRef={btnRef}
      isOpen={!!isOpen}
      placement={placement}
      size={size}
      onClose={onClose}
    >
      <DrawerOverlay top="64px" zIndex={zIndex} />
      <DrawerContent
        alignItems="center"
        containerProps={{ top: "64px", zIndex: zIndex }}
        marginTop="64px"
        zIndex={zIndex}
      >
        <DrawerBody width="100%">{children}</DrawerBody>
        <DrawerFooter>{footer}</DrawerFooter>
      </DrawerContent>
    </ChakraDrawer>
  );
};

export default Drawer;
