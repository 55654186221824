import { Box, Icon, chakra, forwardRef } from "@chakra-ui/react";
import React from "react";
import { IconType } from "react-icons/lib";
import { NavLink, useInRouterContext } from "react-router-dom";

interface MobileNavbarButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  active?: boolean;
  icon: IconType;
  label?: string;
  to?: string;
}

const MobileNavbarButton = forwardRef<MobileNavbarButtonProps, "button">(
  (props, ref) => {
    const legacyComponent = !useInRouterContext();
    const { active, icon, label, ...otherProps } = props;

    return (
      <chakra.button
        __css={{
          "& polyline": {
            color: "status.success",
          },
          "&[aria-current]": {
            color: "tertiary",
          },
        }}
        _hover={{
          base: { background: "#ffffff80" },
          lg: { background: "unset", textDecoration: "underline" },
        }}
        aria-expanded={active}
        as={otherProps.to ? (legacyComponent ? "a" : NavLink) : undefined}
        borderRadius="1em"
        color={active ? "tertiary" : "white"}
        display="flex"
        href={otherProps.to && legacyComponent ? props.to : undefined}
        padding="0.3em"
        ref={ref}
        {...otherProps}
      >
        <Icon as={icon} height="26px" width="26px" />
        {label && (
          <Box
            __css={{ wordWrap: "normal" }}
            display={{ base: "none", lg: "inline" }}
            marginLeft=".5em"
            maxWidth="9em"
            overflow="hidden"
            textOverflow="ellipsis;"
            textStyle="menuItem"
            whiteSpace="nowrap"
          >
            {label}
          </Box>
        )}
      </chakra.button>
    );
  }
);

export default MobileNavbarButton;
