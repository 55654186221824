import useFetchCallback from "./useFetchCallback";

const useSignOut = () => {
  const [signOutCallback] = useFetchCallback(
    GLOBALS.root + "/users/sign_out",
    "DELETE"
  );

  return () =>
    signOutCallback({}, () => {
      location.reload();
    });
};

export default useSignOut;
